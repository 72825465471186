<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase">claim request detail</h1>

    <div v-if="formatSwapTokoStakingData" class="box box--md box--white mt-16">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Full Name</p>
                <h2 class="headline--md mt-4">
                  {{
                    formatSwapTokoStakingData.user && formatSwapTokoStakingData.user.full_name
                      ? formatSwapTokoStakingData.user.full_name
                      : '_'
                  }}
                </h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Email</p>
                <h2 class="headline--md mt-4">{{ formatSwapTokoStakingData.user.email }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Wallet Address</p>
                <h2 class="headline--md mt-4">{{ formatSwapTokoStakingData.user_wallet_address }}</h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Plan</p>
                <h2 class="headline--md mt-4">{{ formatSwapTokoStakingData.feStakingData.plan_name }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Plan joined date</p>
                <h2 class="headline--md mt-4">{{ formatSwapTokoStakingData.staking_order.start_at | moment_lts }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Plan end date</p>
                <h2 class="headline--md mt-4">
                  {{ formatSwapTokoStakingData.staking_order.end_at_offchain | moment_lts }}
                </h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Plan Rate (%)</p>
                <h2 class="headline--md mt-4">{{ formatSwapTokoStakingData.feStakingData.rate | rate_percent }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Withdraw Rate (%)</p>
                <h2 class="headline--md mt-4">{{ formatSwapTokoStakingData.rate | rate_percent }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Staking Order Status</p>
                <h2
                  class="headline--md mt-4"
                  v-html="formatStakingStatus(formatSwapTokoStakingData.staking_order.status)"
                />
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Total deposit TOKO Ver. 1</p>
                <h2 class="headline--md mt-4">
                  {{ formatSwapTokoStakingData.staking_order.deposited_amount | float_number_with_commas }}
                </h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Total TOKO Ver. 2 send back</p>
                <h2 class="headline--md mt-4">
                  {{ formatSwapTokoStakingData.withdraw_amount | float_number_with_commas }}
                </h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Transfer Status</p>
                <h2 class="headline--md mt-4" v-html="formatSwapTokoStakingStatus(formatSwapTokoStakingData.status)" />
              </div>
            </td>
          </tr>

          <tr v-if="formatSwapTokoStakingData.user.blacklist">
            <td>
              <p class="statistic__title text-warm">Black List</p>
              <h2 class="headline--md mt-4 text-bold text-error">
                {{ formatSwapTokoStakingData.user.blacklist | format_blacklist_type }}
              </h2>
            </td>
            <td />
            <td />
          </tr>

          <tr
            v-if="
              (enableRejectAction || enableApproveAction) &&
              currentUser &&
              $auth('Page', currentUser, $accessRule.SwapTokoUpdate).canView()
            "
          >
            <td class="has-border" colspan="3">
              <div class="d-flex">
                <div>
                  <p v-if="enableApproveAction" class="headline">
                    Would you like to send
                    <span class="text-bold">{{ formatSwapTokoStakingData.withdraw_amount }}</span> TOKO Ver. 2 to this
                    wallet address <br />
                    after finishing the review?
                  </p>
                  <p v-if="isBlacklist" class="headline"><toko-warning-icon /> This is blacklist account !</p>
                </div>

                <div class="text-center">
                  <button v-if="enableRejectAction" class="btn btn--red-bordered" @click="onOpenRejectConfirmDialog">
                    Reject
                  </button>
                  <button v-if="enableApproveAction" class="btn btn--primary" @click="onOpenApproveConfirmDialog">
                    Transfer
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <p v-if="isApprovedFail" class="mt-8 text-italic text-error">Please contact admin to handle this case!</p>

    <div v-if="isRejected || isApproved">
      <h2 class="headline--md text-bold text-uppercase mt-24">Admin review detail</h2>
      <div class="box box--md box--white mt-16">
        <table class="table table--list three-items">
          <tbody>
            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Full Name</p>
                  <h2 class="headline--md mt-4">{{ adminReview.full_name }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Email</p>
                  <h2 class="headline--md mt-4">{{ adminReview.email }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Review at</p>
                  <h2 class="headline--md mt-4">{{ formatSwapTokoStakingData.admin_handle_at | moment_lts }}</h2>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="statistic">
                  <p v-if="isRejected" class="statistic__title text-warm">Reject Note</p>
                  <p v-else class="statistic__title text-warm">Approved Note</p>
                  <h2 class="headline--md mt-4">{{ formatSwapTokoStakingData.admin_note }}</h2>
                </div>
              </td>
              <td v-if="isApproved">
                <div class="statistic">
                  <p class="statistic__title text-warm">Tnx. Hash</p>
                  <router-link
                    class="headline--md mt-4 link--primary link--underline"
                    :to="`/etherscan/${formatSwapTokoStakingData.tnx_hash}`"
                    >{{ formatSwapTokoStakingData.tnx_hash }}</router-link
                  >
                </div>
              </td>
              <td v-else />
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="enableRejectAction || enableApproveAction">
      <confirm-dialog
        v-if="isConfirmRejectRequest"
        :open.sync="isConfirmRejectRequest"
        confirmText="Are you sure to reject ?"
        confirmButtonText="Continue"
        confirmButtonClass="text-purple"
        @confirm="onRejectSwapTokoStaking"
      >
        <div class="form-group mt-24">
          <textarea
            v-model="adminNote"
            placeholder="Reject note"
            rows="4"
            cols="50"
            type="textarea"
            class="form-control"
          />
        </div>
      </confirm-dialog>

      <confirm-dialog
        v-if="isConfirmApproveRequest"
        :open.sync="isConfirmApproveRequest"
        confirmText="Are you sure you want to send TOKO Ver. 2 to this account?"
        confirmButtonText="Continue"
        confirmButtonClass="text-purple"
        @confirm="onApproveSwapTokoStaking"
      >
        <div class="form-group mt-24">
          <textarea
            v-model="adminNote"
            placeholder="Approve note"
            rows="4"
            cols="50"
            type="textarea"
            class="form-control"
          />
        </div>
      </confirm-dialog>
    </div>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { SWAP_TOKO_STAKING_STATUS } from '@/utils/key-mapping'
import formatSwapTokoStatus from '@/mixins/swap-toko-status'
import formatStakingStatus from '@/mixins/staking-status'
import toggleLoading from '@/mixins/toggle-loading'
const ConfirmDialog = () => import('@/components/ConfirmDialog')
const TokoWarningIcon = () => import('@/ui/toko-icons/TokoWarning')

export default {
  data() {
    return {
      requestStatus: SWAP_TOKO_STAKING_STATUS,
      isConfirmApproveRequest: false,
      isConfirmRejectRequest: false,
      adminNote: ''
    }
  },

  mixins: [formatSwapTokoStatus, formatStakingStatus, toggleLoading],

  computed: {
    ...mapState('swapTokoStaking', ['swapTokoStaking']),

    ...mapGetters('swapTokoStaking', ['formatSwapTokoStakingData']),

    ...mapState('boUsers', ['boUsers']),

    ...mapState('smartContract', ['stakeSmartContract']),

    ...mapState('staking', ['stakingPlans']),

    enableRejectAction() {
      return (
        this.swapTokoStaking &&
        this.swapTokoStaking.status === this.requestStatus.claimInit &&
        (this.swapTokoStaking.status === this.requestStatus.claimInit || this.isBlacklist)
      )
    },

    enableApproveAction() {
      return this.swapTokoStaking && this.swapTokoStaking.status === this.requestStatus.claimInit && !this.isBlacklist
    },

    isApprovedFail() {
      return this.swapTokoStaking && this.swapTokoStaking.status === this.requestStatus.adminTransferFailed
    },

    isBlacklist() {
      return this.swapTokoStaking && this.swapTokoStaking.user.blacklist > 0
    },

    isRejected() {
      return this.swapTokoStaking && this.swapTokoStaking.status === this.requestStatus.adminReject
    },

    isApproved() {
      return this.swapTokoStaking && this.swapTokoStaking.status === this.requestStatus.adminTransferSuccess
    },

    adminReview() {
      let admin
      if (this.swapTokoStaking && this.boUsers) {
        admin = this.boUsers.filter(item => item.id === this.swapTokoStaking.admin_id)[0]
      }

      return admin
    }
  },

  components: {
    ConfirmDialog,
    TokoWarningIcon
  },

  methods: {
    ...mapActions('swapTokoStaking', ['getSwapTokoStaking', 'rejectSwapTokoStaking', 'approveSwapTokoStaking']),

    ...mapMutations('swapTokoStaking', ['CLEAR_SWAP_TOKO_STAKING_DATA']),

    ...mapActions('staking', ['getStakingPlans']),

    ...mapActions('boUsers', ['getBoUsers']),

    onOpenRejectConfirmDialog() {
      this.adminNote = ''
      this.isConfirmRejectRequest = true
    },

    onOpenApproveConfirmDialog() {
      this.adminNote = ''
      this.isConfirmApproveRequest = true
    },

    onCloseConfirmDialogs() {
      this.adminNote = ''
      this.isConfirmRejectRequest = false
      this.isConfirmApproveRequest = false
    },

    getReviewRequestParams() {
      return {
        id: parseInt(this.$route.params.id),
        admin_note: this.$sanitize(this.adminNote)
      }
    },

    onRejectSwapTokoStaking() {
      this.toggleLoading()
      this.rejectSwapTokoStaking(this.getReviewRequestParams())
        .then(() => {
          this.$router.push({ name: 'SwapClaimBonus' })
        })
        .finally(() => {
          this.toggleLoading()
          this.onCloseConfirmDialogs()
        })
    },

    onApproveSwapTokoStaking() {
      this.toggleLoading()
      this.approveSwapTokoStaking(this.getReviewRequestParams())
        .then(() => {
          this.$router.push({ name: 'SwapClaimBonus' })
        })
        .finally(() => {
          this.toggleLoading()
          this.onCloseConfirmDialogs()
        })
    }
  },

  async created() {
    this.CLEAR_SWAP_TOKO_STAKING_DATA()
    try {
      await this.getStakingPlans()
      if (!this.boUsers) await this.getBoUsers()
      await this.getSwapTokoStaking(this.$route.params.id)
    } catch (e) {
      this.toggleLoading()
      throw e
    }

    this.toggleLoading()
  }
}
</script>
